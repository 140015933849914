
import "./landing-page.scss";
import { Options, setup, Vue } from "vue-class-component";
import { useMeta } from "vue-meta";
import Page from "@/components/page/page.vue";
import Panel from "@/components/panel/panel.vue";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_LANDING_PAGE } from "@/store/modules/landing-page/landing-page.actions";
import { LANDING_PAGE_ENTRY_ID } from "@/store/api.config";
import { LandingPageEntity } from "@/store/modules/landing-page/landing-page.model";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "LandingPage",
  components: {
    Page,
    Panel,
    RichTextRenderer,
  },
})
export default class LandingPage extends Vue {
  //#region Meta
  meta = setup(() =>
    useMeta({
      title: "Home",
      htmlAttrs: { lang: "en", amp: true },
    })
  );
  //#endregion

  //#region Component
  isLoading = true;
  landingPageEntity: LandingPageEntity | null = null;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_LANDING_PAGE, LANDING_PAGE_ENTRY_ID);
    this.landingPageEntity = this.$store.getters.landingPageEntity;

    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getLandingPageSplashImageUrl(): string {
    return getImageUrlFromContentfulEntity(
      this.landingPageEntity.landingPageSplashImage
    );
  }

  dealsText = `
    Our Deals
    Don't know how to start your renewable energy journey? Our team are here to make it easier for you.
    At spartan Energy we offer Three great entry areas depending on your needs.
    Budget - For customers looking for the most affordable energy system that dosen't break the bank and gets you started on your energy saving journey.
    Value - For customers wanting the best value for money providing them the best return.
    Premium - The top of the range products/brands which include Panels, Inverters, Batteries
  `;
  //#endregion
}
LandingPage.registerHooks([
  "beforeRouteEnter",
  "beforeRouteUpdate",
  "beforeRouteLeave",
]);
